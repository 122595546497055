<div class="input-group">
  <input
    class="form-control form-control-lg form-control-solid"
    [placeholder]="placeholder"
    [formControl]="formControl"
    name="dp"
    ngbDatepicker
    #d="ngbDatepicker"
    (dateSelect)="control.markAllAsTouched()"
  />
  <button
    class="btn btn-primary bi bi-calendar3"
    (click)="onButtonClick(d)"
    type="button"
  ></button>
</div>
<ui-validation-form
  *ngIf="control['touched']"
  [control]="control"
></ui-validation-form>
