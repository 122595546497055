<button type="button" class="btn-lg" [class]="btnClass" [disabled]="disabled">
  <ng-container *ngIf="isLoading">
    <span class="indicator-progress d-block">
      {{ 'common.placeholders.wait' | transloco }}
      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <span class="indicator-label">{{ buttonText }}</span>
  </ng-container>
</button>
