<textarea
  *ngIf="control"
  [formControl]="formControl"
  [placeholder]="placeholder"
  class="form-control textarea"
  [ngClass]="{
    'bg-transparent': !global,
    'form-control-lg form-control-solid': global,
    'is-invalid': !skipValidation && control['touched'] && control.invalid,
    'is-valid': !skipValidation && control.valid,
  }"
></textarea>
<ui-validation-form
  *ngIf="control && control['touched']"
  [control]="control"
></ui-validation-form>
