<div
  class="image-input image-input-outline"
  data-kt-image-input="true"
  style="background-image: url('./assets/media/avatars/blank.png')"
>
  <div
    class="image-input-wrapper w-125px h-125px"
    style="background-image: url('./assets/media/avatars/blank.png')"
  ></div>
  <label
    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
    data-kt-image-input-action="change"
    data-bs-toggle="tooltip"
    aria-label="Change avatar"
    data-bs-original-title="Change avatar"
    data-kt-initialized="1"
  >
    <ui-keenicon name="pencil" class="fs-7"></ui-keenicon>
    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
    <input type="hidden" name="avatar_remove" />
  </label>
  <span
    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
    data-kt-image-input-action="cancel"
    data-bs-toggle="tooltip"
    aria-label="Cancel avatar"
    data-bs-original-title="Cancel avatar"
    data-kt-initialized="1"
  >
    <ui-keenicon name="cross" class="fs-2"></ui-keenicon>
  </span>
  <span
    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
    data-kt-image-input-action="remove"
    data-bs-toggle="tooltip"
    aria-label="Remove avatar"
    data-bs-original-title="Remove avatar"
    data-kt-initialized="1"
  >
    <ui-keenicon name="cross" class="fs-2"></ui-keenicon>
  </span>
</div>
<div class="form-text">Allowed file types: png, jpg, jpeg.</div>
