import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  forwardRef,
  Injector,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbPopover,
  NgbPopoverConfig,
  NgbTimepicker,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { noop } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { DateTimeModel } from './date-time.model';
import { CustomDatepickerI18n } from '../../services';
import { DATEPICKER_TIME_FORMAT, UnsubscribeBase } from '@core-utils';

@Component({
  selector: 'ui-form-datepicker-timezone',
  standalone: true,
  imports: [
    NgClass,
    NgbPopover,
    NgIf,
    NgbDatepicker,
    NgbTimepicker,
    DatePipe,
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
  templateUrl: './form-datepicker-timezone.component.html',
  styleUrl: './form-datepicker-timezone.component.scss',
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDatepickerTimezoneComponent),
      multi: true,
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDatepickerTimezoneComponent
  extends UnsubscribeBase
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input() dateString: string | null = '';
  @Input() inputDatetimeFormat = DATEPICKER_TIME_FORMAT;
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() seconds = true;
  @Input() disabled = false;
  @Input() isMinDateToday = false;
  showTimePickerToggle = false;
  datetime: DateTimeModel = new DateTimeModel();
  minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  private firstTimeAssign = true;
  @ViewChild(NgbDatepicker) private dp!: NgbDatepicker;
  @ViewChild(NgbPopover) private popover!: NgbPopover;
  private onTouched: () => void = noop;
  private onChange: (value: string | null) => void = noop;
  protected ngControl: NgControl | null = null;

  constructor(
    private config: NgbPopoverConfig,
    private injector: Injector,
  ) {
    super();
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    if (this.isMinDateToday) {
      const today = new Date();
      this.minDate = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
    }
  }

  ngAfterViewInit(): void {
    this.addSubscription = this.popover.hidden.subscribe(() => {
      this.showTimePickerToggle = false;
    });

    this.addSubscription = this.popover.shown.subscribe(() => {
      if (
        this.dp &&
        this.datetime &&
        this.datetime.year &&
        this.datetime.month
      ) {
        this.dp.navigateTo({
          year: this.datetime.year,
          month: this.datetime.month,
        });
      }
    });
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeModel.fromLocalString(newModel),
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event: Event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  onInputChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    if (!target) return;

    const value = target.value.trim();
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
    } else {
      this.datetime = new DateTimeModel();
      this.dateString = value === '' ? '' : value;
      this.onChange(this.dateString);
    }

    this.setDateStringModel();
  }

  onDateChange($event: NgbDateStruct | string | null): void {
    if ($event && typeof $event !== 'string' && 'year' in $event) {
      $event = `${$event.year}-${$event.month}-${$event.day}`;
    }

    const date = DateTimeModel.fromLocalString($event as string);
    if (!date) return;

    this.datetime = Object.assign(new DateTimeModel(), this.datetime, date);
    this.dp.navigateTo({
      year: this.datetime.year,
      month: this.datetime.month,
    });
    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct): void {
    Object.assign(this.datetime, event);
    this.setDateStringModel();
  }

  setDateStringModel(): void {
    this.dateString = this.datetime.toString();
    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
    } else if (this.dateString !== null) {
      this.firstTimeAssign = false;
      this.onChange(this.dateString);
    }
  }

  inputBlur(): void {
    this.onTouched();
  }

  toggleDatepicker() {
    this.popover.toggle();
  }
}
