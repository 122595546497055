import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'ui-loading-button',
  standalone: true,
  imports: [NgIf, TranslocoPipe],
  templateUrl: './loading-button.component.html',
  styleUrl: './loading-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingButtonComponent {
  @Input() btnClass = 'btn btn-primary';
  @Input() buttonText = 'Button';
  @Input() isLoading = false;
  @Input() disabled = false;

  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled;
  }
}
