<ng-container *ngIf="formControl; else noFormControl">
  <div [class]="class || 'mb-5'">
    <ng-select
      [searchable]="searchable"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [clearable]="clearable"
    >
      <ng-option *ngFor="let data of dataSelect" [value]="data.id">
        {{ isTransloco ? (data.name | transloco) : data.name }}
      </ng-option>
    </ng-select>
  </div>
  <ui-validation-form
    *ngIf="control!['touched']"
    [control]="control!"
  ></ui-validation-form>
</ng-container>

<ng-template #noFormControl>
  <div [class]="class || 'mb-5'">
    <ng-select
      [searchable]="searchable"
      [placeholder]="placeholder"
      [clearable]="clearable"
      [(ngModel)]="value"
      [disabled]="disabled"
      (ngModelChange)="onValueChange($event)"
    >
      <ng-option *ngFor="let data of dataSelect" [value]="data.id">
        {{ isTransloco ? (data.name | transloco) : data.name }}
      </ng-option>
    </ng-select>
  </div>
</ng-template>
