<div class="symbol symbol-circle symbol-40px overflow-hidden me-3">
  <ng-container *ngIf="imageUrl; else noImage">
    <img [src]="imageUrl" alt="{{ userName }}" class="symbol-label" />
  </ng-container>
  <ng-template #noImage>
    <div class="symbol-label fs-3" [ngClass]="colorClass">
      {{ firstLetter }}
    </div>
  </ng-template>
</div>
