<div class="custom-datepicker p-0" [class]="class">
  <div class="dp-hidden position-absolute">
    <div class="input-group">
      <input
        #datepicker="ngbDatepicker"
        ngbDatepicker
        class="form-control form-control-lg"
        outsideDays="hidden"
        tabindex="-1"
        [autoClose]="'outside'"
        [displayMonths]="2"
        [dayTemplate]="t"
        [startDate]="fromDate!"
        (dateSelect)="onDateSelection($event)"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div (click)="datepicker.toggle()" class="input-group">
    <input
      #dpFromDate
      readonly
      class="form-control form-control-lg cursor-pointer"
      name="dpFromDate"
      [placeholder]="
        placeholder || 'common.placeholders.datepicker' | transloco
      "
      [value]="
        (formatter.format(fromDate) ||
          ('common.placeholders.datepicker' | transloco)) +
        '/' +
        (formatter.format(toDate) ||
          ('common.placeholders.datepicker' | transloco))
      "
      (input)="onFromDateInput($event)"
    />
    <button
      class="btn"
      [ngClass]="{
        'bi bi-calendar3 btn-primary': !fromDate || !toDate,
        'bi bi-trash btn-danger': fromDate && toDate,
      }"
      type="button"
      (click)="handleClick($event)"
    ></button>
  </div>
</div>
