import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ui-form-label',
  standalone: true,
  imports: [NgClass],
  templateUrl: './form-label.component.html',
  styleUrl: './form-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
  private _required = false;
  @Input() class = 'mb-2';
  @Input()
  set required(value: boolean | string) {
    this._required = value !== null && `${value}` !== 'false';
  }

  get required(): boolean {
    return this._required;
  }
}
