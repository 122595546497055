<div class="table-responsive">
  <table
    class="table position-relative table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
  >
    <thead>
      <tr class="fw-bolder text-muted">
        <th
          *ngFor="let column of columns; let last = last"
          [class.text-end]="last"
          [class]="column?.class"
        >
          <ui-sortable-header
            [column]="column"
            [t]="t"
            [sortField]="sortField"
            [sortOrder]="sortOrder"
            (sortChanged)="onSortChanged($event)"
          ></ui-sortable-header>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-content></ng-content>
    </tbody>
  </table>
</div>
<p *ngIf="!collectionSize" class="text-xl text-center">
  {{ 'common.placeholders.noResults' | transloco }}.
</p>
<ui-form-pagination
  *ngIf="collectionSize"
  [collectionSize]="collectionSize"
  [page]="page"
  [pageSize]="pageSize"
  (paginationValue)="paginationValue.emit($event)"
></ui-form-pagination>
