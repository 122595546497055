import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgOptionComponent, NgSelectComponent } from '@ng-select/ng-select';
import { NgForOf, NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';
import { ValidationFormComponent } from '../validation-form/validation-form.component';
import { SelectOptionsInterface } from '../../types';

@Component({
  selector: 'ui-form-select',
  standalone: true,
  imports: [
    NgSelectComponent,
    ReactiveFormsModule,
    NgOptionComponent,
    NgForOf,
    NgIf,
    TranslocoPipe,
    FormsModule,
    ValidationFormComponent,
  ],
  templateUrl: './form-select.component.html',
  styleUrl: './form-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectComponent {
  @Input() control?: AbstractControl;
  @Input() placeholder = '';
  @Input() searchable = false;
  @Input() class = '';
  @Input() isTransloco = false;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() value: unknown = null;
  @Input() dataSelect: SelectOptionsInterface[] = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() valueChange = new EventEmitter<any>();

  get formControl(): FormControl {
    if (this.disabled) this.control?.disable();
    return this.control as FormControl;
  }

  onValueChange(newValue: number) {
    this.valueChange.emit(newValue);
  }
}
