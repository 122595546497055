<input
  *ngIf="mask && formControl; else noMask"
  [type]="type"
  [formControl]="formControl"
  [placeholder]="placeholder"
  [style.background-image]="!backgroundImageView ? 'none' : ''"
  [mask]="mask"
  [class]="class"
  [showMaskTyped]="true"
  [keepCharacterPositions]="true"
  [appPositiveNumbers]="noNegative"
  [ngClass]="{
    'bg-transparent': !global,
    'form-control-lg form-control-solid': global,
    'is-invalid': !skipValidation && control['touched'] && control.invalid,
    'is-valid': !skipValidation && control.valid,
  }"
  class="form-control form-control-lg"
/>
<ng-template #noMask>
  <input
    *ngIf="formControl; else noFormControl"
    [type]="type"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [class]="class"
    [appPositiveNumbers]="noNegative"
    [ngClass]="{
      'bg-transparent': !global,
      'form-control-lg form-control-solid': global,
      'is-invalid': !skipValidation && control['touched'] && control.invalid,
      'is-valid': !skipValidation && control.valid,
    }"
    class="form-control form-control-lg"
  />
</ng-template>
<ng-template #noFormControl>
  <input
    [value]="value"
    [type]="type"
    [placeholder]="placeholder"
    [class]="class"
    [appPositiveNumbers]="noNegative"
    [ngClass]="{
      'bg-transparent': !global,
      'form-control-lg form-control-solid': global,
    }"
    class="form-control form-control-lg"
  />
</ng-template>
<ui-validation-form
  *ngIf="formControl && !skipValidation && control['touched']"
  [control]="control"
></ui-validation-form>
