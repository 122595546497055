import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { ValidationFormComponent } from '../validation-form/validation-form.component';

@Component({
  selector: 'ui-form-textarea',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgxMaskDirective,
    ReactiveFormsModule,
    NgClass,
    ValidationFormComponent,
  ],
  templateUrl: './form-textarea.component.html',
  styleUrl: './form-textarea.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTextareaComponent {
  @Input() control!: AbstractControl;
  @Input() global = true;
  @Input() placeholder = '';
  @Input() skipValidation = false;

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
