<div
  *ngIf="!formControl"
  class="form-check form-check-solid form-switch form-check-custom fv-row"
  [class]="class"
>
  <input
    class="form-check-input w-45px h-30px cursor-pointer"
    type="checkbox"
    [checked]="checked"
    [id]="id"
    (change)="change($event)"
  />
  <label class="form-check-label cursor-pointer" [for]="id">{{
    placeholder
  }}</label>
</div>

<div
  *ngIf="formControl"
  class="form-check form-check-solid form-switch form-check-custom fv-row"
  [class]="class"
>
  <input
    class="form-check-input w-45px h-30px cursor-pointer"
    type="checkbox"
    [id]="formControl.value"
    [formControl]="formControl"
  />
  <label class="form-check-label cursor-pointer" [for]="formControl.value">{{
    placeholder
  }}</label>
</div>
