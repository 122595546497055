import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'ui-form-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './form-checkbox.component.html',
  styleUrl: './form-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCheckboxComponent {
  @Input() control!: AbstractControl;
  @Input() placeholder = '';
  @Input() class = '';

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
