import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { FormSelectComponent } from '../form-select/form-select.component';
import { PaginationInterface, SelectOptionsInterface } from '../../types';
import { UnsubscribeBase } from '@core-utils';
@Component({
  selector: 'ui-form-pagination',
  standalone: true,
  imports: [NgbPagination, FormSelectComponent],
  templateUrl: './form-pagination.component.html',
  styleUrl: './form-pagination.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPaginationComponent extends UnsubscribeBase {
  @Input() collectionSize = 10;
  @Input() page = 1;
  @Input() pageSize = 5;
  @Output() paginationValue = new EventEmitter<PaginationInterface>();
  pageSizeList: SelectOptionsInterface[] = [
    { id: 5, name: '5' },
    { id: 10, name: '10' },
    { id: 15, name: '15' },
    { id: 20, name: '20' },
  ];

  onPageChange(page: number) {
    this.page = page;
    this.emitPaginationValue();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.page = 1;
    this.emitPaginationValue();
  }

  private emitPaginationValue() {
    this.paginationValue.emit({ limit: this.pageSize, page: this.page });
  }
}
