<ng-container
  *ngIf="
    ((statusChanges$ | async) || (control['touched'] && errorMessageKey)) &&
    !showImmediately
  "
>
  <div class="fv-plugins-message-container">
    <div class="fv-help-block">
      <ng-container *transloco="let t; prefix: 'common.validation'">
        <span role="alert">
          {{ t(errorMessageKey, errorMessageParams) }}
        </span>
      </ng-container>
    </div>
  </div>
</ng-container>

<div
  *ngIf="((statusChanges$ | async) || errorMessageKey) && showImmediately"
  class="fv-plugins-message-container"
>
  <div class="fv-help-block">
    <ng-container *transloco="let t; prefix: 'common.validation'">
      <span role="alert">
        {{ t(errorMessageKey, errorMessageParams) }}
      </span>
    </ng-container>
  </div>
</div>
