import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { NgClass, NgIf } from '@angular/common';
import { PositiveNumbersDirective } from '../../directives';
import { ValidationFormComponent } from '../validation-form/validation-form.component';

@Component({
  selector: 'ui-form-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ValidationFormComponent,
    NgxMaskDirective,
    NgClass,
    NgIf,
    PositiveNumbersDirective,
    FormsModule,
    PositiveNumbersDirective,
  ],
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent {
  @Input() control!: AbstractControl;
  @Input() global = true;
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() mask = '';
  @Input() class = '';
  @Input() skipValidation = false;
  @Input() backgroundImageView = true;
  @Input() noNegative = false;
  @Input() value: string | number = '';

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
