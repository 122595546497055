import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { NgIf } from '@angular/common';
import { CustomAdapter } from '../../services/custom-addapter';
import { CustomDateParserFormatter } from '../../services/custom-date-parse-formatter';
import { ValidationFormComponent } from '../validation-form/validation-form.component';
import { CustomDatepickerI18n } from '../../services';
import { DATEPICKER_CONSTANTS } from '@core-utils';

@Component({
  selector: 'ui-form-datepicker',
  standalone: true,
  imports: [
    NgbInputDatepicker,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgIf,
    ValidationFormComponent,
  ],
  templateUrl: './form-datepicker.component.html',
  styleUrl: './form-datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class FormDatepickerComponent implements AfterViewInit {
  @Input() control!: AbstractControl;
  @Input() placeholder = 'Birthday date';
  @Input() isDateOfBirthday = false;
  constructor(private config: NgbDatepickerConfig) {
    this.config.minDate = DATEPICKER_CONSTANTS.MIN_DATE;
    this.config.weekdays = DATEPICKER_CONSTANTS.WEEKDAYS_FORMAT;
  }

  ngAfterViewInit() {
    this.config.maxDate = this.isDateOfBirthday
      ? DATEPICKER_CONSTANTS.MAX_DATE_WITH_AGE_LIMIT
      : DATEPICKER_CONSTANTS.MAX_DATE;
  }

  onButtonClick(datepicker: NgbInputDatepicker) {
    datepicker.toggle();
    this.control.markAllAsTouched();
  }

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
