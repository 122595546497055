import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import {
  NgHeaderTemplateDirective,
  NgMultiLabelTemplateDirective,
  NgOptgroupTemplateDirective,
  NgOptionComponent,
  NgOptionTemplateDirective,
  NgSelectComponent,
  NgSelectConfig,
} from '@ng-select/ng-select';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ValidationFormComponent } from '../validation-form/validation-form.component';
import { SelectOptionsInterface } from '../../types';

@Component({
  selector: 'ui-form-multi-select',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgOptionComponent,
    NgSelectComponent,
    TranslocoPipe,
    ValidationFormComponent,
    ReactiveFormsModule,
    SlicePipe,
    NgMultiLabelTemplateDirective,
    NgOptgroupTemplateDirective,
    FormsModule,
    NgOptionTemplateDirective,
    NgHeaderTemplateDirective,
    NgClass,
  ],
  templateUrl: './form-multi-select.component.html',
  styleUrl: './form-multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMultiSelectComponent {
  @Input() control!: AbstractControl;
  @Input() placeholder = '';
  @Input() skipValidation = false;
  @Input() searchable = false;
  @Input() class = '';
  @Input() isTransloco = false;
  @Input() virtualScroll = false;
  @Input() dataSelect: SelectOptionsInterface[] = [];
  @Input() value: string[] | null = [];
  @Output() closeSelect = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<string[]>();
  isAllSelected = false;

  constructor(
    private config: NgSelectConfig,
    private translocoService: TranslocoService,
  ) {
    this.config.notFoundText = this.translocoService.translate(
      'common.placeholders.noResults',
    );
  }

  get formControl(): FormControl {
    return this.control as FormControl;
  }

  truncate(value: string): string {
    return value.length > 6 ? value.substring(0, 6) + '...' : value;
  }

  selectAll() {
    if (!this.dataSelect) return;
    const allIds = this.dataSelect.map((item) => item.id);
    const currentSelection = this.formControl.value || [];
    if (currentSelection.length === allIds.length) {
      this.formControl.setValue([]);
      this.isAllSelected = false;
    } else {
      this.formControl.setValue(allIds);
      this.isAllSelected = true;
    }
  }

  selectModelAll() {
    if (!this.dataSelect) return;
    const allIds = this.dataSelect.map((item) => item.id);
    const currentSelection = this.value || [];
    if (currentSelection?.length === allIds.length) {
      this.valueChange.emit([]);
      this.isAllSelected = false;
    } else {
      this.valueChange.emit(allIds);
      this.isAllSelected = true;
    }
  }

  onSelectChange(selectedItems: SelectOptionsInterface[]) {
    const allIds = this.dataSelect.map((item) => item.id);
    this.isAllSelected = selectedItems.length === allIds.length;
    this.valueChange.emit(selectedItems.map((item) => item.id));
  }

  removeItem(itemId: number) {
    const currentSelection = this.formControl.value || [];
    this.formControl.setValue(
      currentSelection.filter((id: number) => id !== itemId),
    );
    this.onSelectChange(this.formControl.value);
  }

  removeModelItem(itemId: string) {
    const currentSelection = this.value || [];
    this.valueChange.emit(
      currentSelection.filter((id: string) => id !== itemId),
    );
  }
}
