<div class="input-group mr-2">
  <input
    [ngClass]="ngControl!.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control form-control-lg form-control-solid cursor-pointer"
    (blur)="inputBlur()"
    [ngModel]="dateString! | date: inputDatetimeFormat"
    (change)="onInputChange($event)"
    [disabled]="disabled"
    (click)="toggleDatepicker()"
    [placeholder]="'common.placeholders.selectDate' | transloco"
    readonly
  />

  <button
    class="btn btn-primary bi bi-calendar3 calendar-btn"
    [ngbPopover]="calendarContent"
    [disabled]="disabled"
    placement="bottom"
    type="button"
  ></button>
</div>

<ng-template #calendarContent>
  <div>
    <div class="d-flex flex-column gap-3 p-3" *ngIf="!showTimePickerToggle">
      <ngb-datepicker
        #dp
        name="datepicker"
        [ngModel]="datetime"
        [minDate]="minDate"
        (ngModelChange)="onDateChange($event)"
      ></ngb-datepicker>
      <button
        class="btn btn-primary bi bi-clock calendar-btn"
        [disabled]="!datetime.day"
        [ngbPopover]="''"
        placement="bottom"
        type="button"
        (click)="toggleDateTimeState($event)"
      ></button>
    </div>
    <div class="d-flex flex-column gap-3 p-3" *ngIf="showTimePickerToggle">
      <div class="mt-auto">
        <ngb-timepicker
          #tp
          name="timepicker"
          [ngModel]="datetime"
          (ngModelChange)="onTimeChange($event)"
          [seconds]="seconds"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="secondStep"
        ></ngb-timepicker>
      </div>
      <button
        class="btn btn-primary bi bi-calendar3"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      ></button>
    </div>
  </div>
</ng-template>
