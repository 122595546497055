import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { JsonPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  HeaderTableColumnInterface,
  PaginationInterface,
  SortChangedInterface,
  TranslocoFunction,
} from '../../types';
import { FormPaginationComponent } from '../../form';
import { SortableHeaderComponent } from '../sortable-header/sortable-header.component';

@Component({
  selector: 'ui-table',
  standalone: true,
  imports: [
    TranslocoDirective,
    NgForOf,
    NgTemplateOutlet,
    NgIf,
    JsonPipe,
    TranslocoPipe,
    FormPaginationComponent,
    SortableHeaderComponent,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  @Input() columns: HeaderTableColumnInterface[] = [];
  @Input() t!: TranslocoFunction;
  @Input() collectionSize = 10;
  @Input() page = 1;
  @Input() pageSize = 5;
  @Input()
  set sortableField(value: SortChangedInterface | null) {
    this.sortField = value?.sortField || '';
    this.sortOrder = value?.sortOrder || 'ASC';
  }
  @Output() paginationValue = new EventEmitter<PaginationInterface>();
  @Output() sortableValue = new EventEmitter<SortChangedInterface>();
  sortField: string | null = this.sortableField?.sortField || '';
  sortOrder = this.sortableField?.sortOrder || 'ASC';

  onSortChanged(event: SortChangedInterface) {
    this.sortField = event.sortField;
    this.sortableValue.emit(event);
  }
}
