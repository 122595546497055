import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ui-form-switch',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf],
  templateUrl: './form-switch.component.html',
  styleUrl: './form-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSwitchComponent {
  @Input() control?: AbstractControl;
  @Input() placeholder = '';
  @Input() class = '';
  @Input() checked = false;
  @Input() id = '';
  @Output() changeInput = new EventEmitter();

  get formControl(): FormControl {
    return this.control as FormControl;
  }

  change(event: Event) {
    this.changeInput.emit(event);
  }
}
