import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'ui-user-symbol',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './user-symbol.component.html',
  styleUrl: './user-symbol.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSymbolComponent implements OnInit {
  @Input() imageUrl = '';
  @Input() userName = '';
  firstLetter = '';
  colorClass = '';

  private colorClasses = [
    { bg: 'bg-light-warning', text: 'text-warning' },
    { bg: 'bg-light-danger', text: 'text-danger' },
    { bg: 'bg-light-primary', text: 'text-primary' },
    { bg: 'bg-light-success', text: 'text-success' },
  ];

  ngOnInit() {
    this.firstLetter = this.userName.charAt(0).toUpperCase();
    this.assignColorClass();
  }

  private assignColorClass() {
    const index = this.firstLetter.charCodeAt(0) % 4;
    this.colorClass = `${this.colorClasses[index].bg} ${this.colorClasses[index].text}`;
  }
}
