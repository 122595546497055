import { inject, Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@jsverse/transloco';
import { I18N_DATEPICKER_VALUES } from '@core-utils';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private transloco = inject(TranslocoService);

  getWeekdayLabel(weekday: number): string {
    return I18N_DATEPICKER_VALUES[this.transloco.getActiveLang()].weekdays[
      weekday - 1
    ];
  }
  override getWeekLabel(): string {
    return I18N_DATEPICKER_VALUES[this.transloco.getActiveLang()].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_DATEPICKER_VALUES[this.transloco.getActiveLang()].months[
      month - 1
    ];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
