<div
  class="d-flex align-items-center gap-4 flex-md-row flex-column justify-content-center py-5 position-relative pagination"
>
  <ngb-pagination
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [(page)]="page"
    [rotate]="true"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
  />
  <div class="position-md-absolute position-static select">
    <ui-form-select
      [value]="pageSize"
      (valueChange)="changePageSize($event)"
      [dataSelect]="pageSizeList"
      [clearable]="false"
      class="m-0 border-transparent custom-control-sm pagination-select"
    ></ui-form-select>
  </div>
</div>
