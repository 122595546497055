<div class="'form-sm form-multiselect'" [class]="class">
  <ng-select
    *ngIf="formControl"
    bindLabel="name"
    class="border-none"
    bindValue="id"
    [searchable]="searchable"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [multiple]="true"
    [closeOnSelect]="false"
    [items]="dataSelect"
    [ngClass]="{ 'all-selected': isAllSelected }"
    (change)="onSelectChange($event)"
    (close)="closeSelect.emit()"
    [virtualScroll]="virtualScroll"
  >
    <ng-template ng-header-tmp>
      <div class="ng-value select-all-option" (click)="selectAll()">
        {{ 'common.buttons.selectAll' | transloco }}
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        *ngFor="let item of items | slice: 0 : 2"
        class="ng-value d-flex align-items-center gap-2 pl-2"
      >
        {{
          isTransloco
            ? truncate($any(item).name | transloco)
            : truncate($any(item).name)
        }}
        <div
          class="text-white cursor-pointer mx-1 my-1"
          (click)="removeItem($any(item).id)"
        >
          &times;
        </div>
      </div>

      <div *ngIf="items.length > 2" class="ng-value px-2 py-1">
        <span class="ng-value-label">
          {{ $any(items).length - 2 }}
          {{ 'common.placeholders.more' | transloco }}...
        </span>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      {{ isTransloco ? ($any(item).name | transloco) : $any(item).name }}
    </ng-template>
  </ng-select>
  <ng-select
    *ngIf="!formControl"
    bindLabel="name"
    class="border-none"
    bindValue="id"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [multiple]="true"
    [closeOnSelect]="false"
    [items]="dataSelect"
    [ngClass]="{ 'all-selected': isAllSelected }"
    (change)="onSelectChange($event)"
    [virtualScroll]="virtualScroll"
  >
    <ng-template ng-header-tmp>
      <div class="ng-value select-all-option" (click)="selectModelAll()">
        {{ 'common.buttons.selectAll' | transloco }}
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        *ngFor="let item of items | slice: 0 : 2"
        class="ng-value d-flex align-items-center gap-2 pl-2"
      >
        {{
          isTransloco
            ? truncate($any(item).name | transloco)
            : truncate($any(item).name)
        }}
        <div
          class="text-white cursor-pointer mx-1 my-1"
          (click)="removeModelItem($any(item).id)"
        >
          &times;
        </div>
      </div>

      <div *ngIf="items.length > 2" class="ng-value px-2 py-1">
        <span class="ng-value-label">
          {{ $any(items).length - 2 }}
          {{ 'common.placeholders.more' | transloco }}...
        </span>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      {{ isTransloco ? ($any(item).name | transloco) : $any(item).name }}
    </ng-template>
  </ng-select>
</div>
<ui-validation-form
  *ngIf="formControl && !skipValidation && control['touched']"
  [control]="control"
></ui-validation-form>
