import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { KeeniconComponent } from '../../partials';
import {
  HeaderTableColumnInterface,
  SortChangedInterface,
  SortOrder,
  TranslocoFunction,
} from '../../types';

@Component({
  selector: 'ui-sortable-header',
  standalone: true,
  imports: [NgIf, NgForOf, NgClass, NgStyle, KeeniconComponent],
  templateUrl: './sortable-header.component.html',
  styleUrl: './sortable-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableHeaderComponent {
  @Input() column!: HeaderTableColumnInterface;
  @Input() sortField: string | null = '';
  @Input() sortOrder: SortOrder | null = 'ASC';
  @Input() t!: TranslocoFunction;
  @Output() sortChanged = new EventEmitter<SortChangedInterface>();

  onSortChanged() {
    const newOrder: SortOrder | null = this.toggleOrder();
    this.sortField = newOrder ? this.column.field : null;
    this.sortOrder = newOrder;
    this.sortChanged.emit({
      sortField: this.sortField,
      sortOrder: newOrder,
    });
  }

  private toggleOrder(): SortOrder | null {
    if (this.sortField === this.column.field) {
      switch (this.sortOrder) {
        case 'ASC':
          return 'DESC';
        case 'DESC':
          return null;
        default:
          return 'ASC';
      }
    } else {
      return 'ASC';
    }
  }
}
