import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { KeeniconComponent } from '../../partials';

@Component({
  selector: 'ui-form-icon-input',
  standalone: true,
  imports: [ReactiveFormsModule, NgxMaskDirective, KeeniconComponent],
  templateUrl: './form-icon-input.component.html',
  styleUrl: './form-icon-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormIconInputComponent {
  @Input() control!: AbstractControl;
  @Input() icon = '';
  @Input() placeholder = '';

  get formControl(): FormControl {
    return this.control as FormControl;
  }
}
