import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KeeniconComponent } from '../../partials';

@Component({
  selector: 'ui-form-picture',
  standalone: true,
  imports: [KeeniconComponent],
  templateUrl: './form-picture.component.html',
  styleUrl: './form-picture.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPictureComponent {}
