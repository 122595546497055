<div
  [ngClass]="[
    !column.withoutSort ? 'cursor-pointer' : 'pointer-events-none',
    column.class || '',
  ]"
  [ngStyle]="{ 'min-width': column.minWidth }"
  (click)="onSortChanged()"
>
  {{ t(column.translationKey) }}
  <span
    class="d-inline-block sort-icon"
    *ngIf="!column.withoutSort && sortField === column.field"
    [class.rotate-180]="sortOrder === 'DESC'"
  >
    <ui-keenicon name="arrow-down"></ui-keenicon>
  </span>
</div>
