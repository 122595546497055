import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgClass } from '@angular/common';
import { CustomDatepickerI18n } from '../../services';
import {
  RangeDatepickerInterface,
  SelectRangeDatepickerInterface,
} from '../../types';
import { CustomAdapter } from '../../services/custom-addapter';
import { CustomDateParserFormatter } from '../../services/custom-date-parse-formatter';
import { DATEPICKER_CONSTANTS } from '@core-utils';

@Component({
  selector: 'ui-form-range-datepicker',
  standalone: true,
  imports: [
    NgbDatepicker,
    NgbInputDatepicker,
    ReactiveFormsModule,
    TranslocoPipe,
    NgClass,
  ],
  templateUrl: './form-range-datepicker.component.html',
  styleUrl: './form-range-datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class FormRangeDatepickerComponent {
  @Input() set range(value: SelectRangeDatepickerInterface | null) {
    this.rangeDatepicker = value;
    this.updateDates();
    if (
      this.range?.select?.dateStart === '' &&
      this.range?.select?.dateEnd === '' &&
      this.toggle
    ) {
      this.openDatepicker();
      this.setFocusDateToCurrent();
    }
  }
  get range(): SelectRangeDatepickerInterface | null {
    return this.rangeDatepicker;
  }
  @Input() placeholder = '';
  @Input() toggle = false;
  @Input() class = '';
  @Output() selectedDates = new EventEmitter();
  @Input() fromDate: NgbDate | null =
    DATEPICKER_CONSTANTS.MIN_DATE_DASHBOARD as NgbDate;
  @Input() toDate: NgbDate | null = DATEPICKER_CONSTANTS.MAX_DATE as NgbDate;
  @ViewChild('datepicker', { static: false }) datepicker!: NgbInputDatepicker;
  hoveredDate: NgbDate | null = null;
  private rangeDatepicker: SelectRangeDatepickerInterface | null = null;

  constructor(
    private calendar: NgbCalendar,
    protected formatter: NgbDateParserFormatter,
  ) {}

  onDateSelection(date: NgbDate) {
    if (
      !this.fromDate ||
      (this.fromDate && this.toDate) ||
      (this.fromDate && date.before(this.fromDate))
    ) {
      this.fromDate = date;
      this.toDate = null;
    } else {
      this.toDate = date;
    }
    this.logSelectedDates();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onFromDateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.fromDate = this.validateInput(this.fromDate, input.value);
  }

  handleClick(event: Event): void {
    if (this.fromDate && this.toDate) {
      this.clearDates(event);
    }
  }

  openDatepicker(): void {
    this.datepicker.toggle();
  }

  clearDates(event: Event): void {
    event.stopPropagation();
    this.fromDate = null;
    this.toDate = null;
    this.logSelectedDates(true);
  }

  private validateInput(
    currentValue: NgbDate | null,
    input: string,
  ): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  private updateDates(): void {
    if (this.range?.select) {
      const { dateStart, dateEnd } = this.range.select;
      this.fromDate = dateStart
        ? (this.formatter.parse(dateStart) as NgbDate)
        : null;
      this.toDate = dateEnd ? (this.formatter.parse(dateEnd) as NgbDate) : null;
    } else {
      this.fromDate = DATEPICKER_CONSTANTS.MIN_DATE_DASHBOARD as NgbDate;
      this.toDate = DATEPICKER_CONSTANTS.MAX_DATE as NgbDate;
    }
  }

  private logSelectedDates(clear = false): void {
    const dateStart = this.fromDate
      ? new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day,
        ).toISOString()
      : null;
    const dateEnd = this.toDate
      ? new Date(
          this.toDate.year,
          this.toDate.month - 1,
          this.toDate.day,
        ).toISOString()
      : null;

    if (dateStart && dateEnd) {
      const dates: RangeDatepickerInterface = { dateStart, dateEnd };
      this.selectedDates.emit(dates);
    }

    if (clear) {
      this.selectedDates.emit({ dateStart: null, dateEnd: null });
    }
  }

  private setFocusDateToCurrent(): void {
    const today = this.calendar.getToday();
    this.datepicker.navigateTo(today);
  }
}
