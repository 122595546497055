<div class="input-group">
  <div class="btn btn-icon btn-primary pointer-events-none">
    <ui-keenicon class="fs-3" [name]="icon"></ui-keenicon>
  </div>
  <input
    type="text"
    [formControl]="formControl"
    class="form-control form-control-solid"
    [placeholder]="placeholder"
  />
</div>
